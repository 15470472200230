
//import logo from './logo.svg';
import './App.scss';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ResultTable from './Components/ResultTable';
import { PublicUrlContext } from './GlobalContext/PublicUrlContext';
import { ApiUrlContext } from './GlobalContext/ApiUrlContext';
import Footer from './ComponentsShared/Footer';
import Imprint from './ComponentsShared/Imprint';
import TopSellers from './ComponentsShared/TopSellers';
import { useEffect, useState } from 'react';
import BackToTopLink from './ComponentsShared/BackToTopLink';
//import BackToTopLink from './ComponentsShared/components/BackToTopLink';
//import BackToTopLink from 'vibi-back-to-top-link/BackToTopLink';
import Faqs from './ComponentsShared/Faqs';
import TopAccessories from './ComponentsShared/TopAccessories';
import PageNotFound from './ComponentsShared/PageNotFound';
import { PreviousLocationProvider } from './GlobalContext/PreviousLocationContext';
import '@smastrom/react-rating/style.css'
import SingleViewItem from './ComponentsShared/SingleViewItem';
import HtmlHeaderTags from './ComponentsShared/HtmlHeaderTags';

function App() {

    const apiUrl = process.env.REACT_APP_API_URL
    const displayType = "drive";
    const hostname = "https://festplattenpreise.info";
    const [initialRender, setInitialRender] = useState(true);
    const [lastUpdated, setLastUpdated] = useState('');
    const [pageTitle, setPageTitle] = useState('Festplatten Preise und Vergleich');
    const [baseUrl, setBaseUrl] = useState(hostname);
    const [teaserImage, setTeaserImage] = useState('');

    useEffect(() => {
        if (initialRender) {
            setInitialRender(false);
        } else {
            const urltemp = apiUrl + 'hddrives/lastUpdatedJson.json';
            fetch(urltemp, {
                method: 'GET'
            })
                .then(response => response.json())
                .then((result) => {
                    setLastUpdated(result.lastUpdated);
                    console.log('lastUpdated', result.lastUpdated);
                })
                .catch((error) => {
                    console.error('Error fetching dataFiles:', error);
                });
        }
    }, [initialRender, apiUrl]);

    const setBaseUrlWithHostname = (url) => {
        setBaseUrl(`${hostname}${url}`);
    };

    const updateBaseUrl = (path) => {
        setBaseUrlWithHostname(path);
    };

    return (
        <>
            <BrowserRouter basename={process.env.REACT_APP_PUBLIC_URL}>
                <PreviousLocationProvider>
                    <PublicUrlContext.Provider value={process.env.REACT_APP_PUBLIC_URL}>
                        <ApiUrlContext.Provider value={process.env.REACT_APP_API_URL}>
                            <div className="App" id="top">
                                <HtmlHeaderTags title={pageTitle} baseurl={baseUrl} type={displayType} teaserImage={teaserImage} />
                                <BackToTopLink />
                                <Routes>

                                    <Route
                                        path={"/"}
                                        element={
                                            <>
                                                {/*<MySearch />*/}
                                                <ResultTable />
                                            </>
                                        } />
                                    <Route path={'/impressum'} element={<Imprint setPageTitle={setPageTitle} updateBaseUrl={updateBaseUrl} />} />
                                    <Route path={'/topseller'} element={
                                        <TopSellers setPageTitle={setPageTitle} updateBaseUrl={updateBaseUrl} jsonUrl="hddrives/topsellerJson.json" />
                                    } />
                                    <Route path={'/topaccessories'} element={
                                        <TopAccessories setPageTitle={setPageTitle} updateBaseUrl={updateBaseUrl} jsonUrl="hddrives/topAccessoriesJson.json" />
                                    } />
                                    <Route path={'/faqs'} element={<Faqs setPageTitle={setPageTitle} updateBaseUrl={updateBaseUrl} />} />
                                    <Route path="/viewdrive/:itemDetailId" element={
                                        <SingleViewItem setPageTitle={setPageTitle} updateBaseUrl={updateBaseUrl} setTeaserImage={setTeaserImage} jsonUrl="hddrives/driveDetailJson.json" productType="drive" />
                                    } />
                                    <Route path="*" element={<PageNotFound />} />
                                </Routes>

                                <Footer lastUpdated={lastUpdated} />
                            </div>
                        </ApiUrlContext.Provider>
                    </PublicUrlContext.Provider>
                </PreviousLocationProvider>
            </BrowserRouter>
        </>
    );
}

export default App;
