
const selectOptions = {

    selectOptionsStars: [
        { value: 'stars5', label: '5 Sterne' },
        { value: 'stars4', label: '4 Sterne' },
        { value: 'stars3', label: '3 Sterne' },
        { value: 'stars2', label: '2 Sterne' },
        { value: 'stars1', label: '1 Stern' }
    ],
    selectOptionsPrices: [
        { value: 'price_100', label: 'bis 100€' },
        { value: 'price_200', label: '100€ - 200€' },
        { value: 'price_300', label: '200€ - 300€' },
        { value: 'price_400', label: '300€ - 400€' },
        { value: 'price_500', label: '400€ - 500€' },
        { value: 'price_600', label: '500€ - 600€' },
        { value: 'price_700', label: '600€ oder mehr' }
    ],
    selectOptionsSizeGb: [
        { value: 'gb_0-500', label: 'bis 500GB' },
        { value: 'gb_500-1000', label: '500GB - 1TB' },
        { value: 'gb_1000-2000', label: '1TB - 2TB' },
        { value: 'gb_2000-4000', label: '2TB - 4TB' },
        { value: 'gb_4000-8000', label: '4TB - 8TB' },
        { value: 'gb_8000-16000', label: '8TB - 16TB' },
        { value: 'gb_16000-32000', label: '16TB - 32TB' },
        { value: 'gb_32000-200000', label: '32TB oder mehr' }
    ],
    selectRpm: [
        { value: 'rpm_5400', label: '5400 U/min' },
        { value: 'rpm_5900', label: '5900 U/min' },
        { value: 'rpm_7200', label: '7200 U/min' },
        { value: 'rpm_10000', label: '10000 U/min' },
        { value: 'rpm_15000', label: '15000 U/min' }
    ],
    selectMaxSpeed: [
        { value: 'max_speed_0-1000', label: 'bis 1000 MB/s' },
        { value: 'max_speed_1000-2000', label: '1000 MB/s - 2000 MB/s' },
        { value: 'max_speed_2000-3000', label: '2000 MB/s - 3000 MB/s' },
        { value: 'max_speed_3000-4000', label: '3000 MB/s - 4000 MB/s' },
        { value: 'max_speed_4000-5000', label: '4000 MB/s - 5000 MB/s' },
        { value: 'max_speed_5000-6000', label: '5000 MB/s - 6000 MB/s' },
        { value: 'max_speed_6000-7000', label: '6000 MB/s - 7000 MB/s' },
        { value: 'max_speed_7000-8000', label: '7000 MB/s - 8000 MB/s' },
        { value: 'max_speed_8000-9000', label: '8000 MB/s - 9000 MB/s' },
        { value: 'max_speed_9000-10000', label: '9000 MB/s - 10000 MB/s' },
    ],
    selectFormFactor: [
        { value: 'formfactor_2.5', label: '2.5 Zoll' },
        { value: 'formfactor_3.5', label: '3.5 Zoll' }
        //{ value: 'formfactor_m2', label: 'M.2' },
        //{ value: 'formfactor_mSATA', label: 'mSATA' },
        //{ value: 'formfactor_u2', label: 'U.2' }
    ],
    selectOptionsBrand: [
        { value: 'brand_adata', label: 'Adata' },
        { value: 'brand_aiolo_innovation', label: 'Aiolo Innovation' },
        { value: 'brand_bliksem', label: 'Bliksem' },
        { value: 'brand_crucial', label: 'Crucial' },
        { value: 'brand_ediloca', label: 'Ediloca' },
        { value: 'brand_emtec', label: 'Emtec' },
        { value: 'brand_fanxiang', label: 'fanxiang' },
        { value: 'brand_fikwot', label: 'Fikwot' },
        { value: 'brand_hgst', label: 'HGST' },
        { value: 'brand_hikvision', label: 'Hikvision' },
        { value: 'brand_hitachi', label: 'Hitachi' },
        { value: 'brand_hynix', label: 'Hynix' },
        { value: 'brand_idiskk', label: 'iDiskk' },
        { value: 'brand_inateck', label: 'Inateck' },
        { value: 'brand_intel', label: 'Intel' },
        { value: 'brand_intenso', label: 'Intenso' },
        { value: 'brand_kingshark', label: 'Kingshark' },
        { value: 'brand_kingspec', label: 'KingSpec' },
        { value: 'brand_kingston', label: 'Kingston' },
        { value: 'brand_lacie', label: 'LaCie' },
        { value: 'brand_lenovo', label: 'Lenovo' },
        { value: 'brand_lexar', label: 'Lexar' },
        { value: 'brand_maxone', label: 'Maxone' },
        { value: 'brand_micron', label: 'Micron' },
        { value: 'brand_netac', label: 'Netac' },
        { value: 'brand_owc', label: 'OWC' },
        { value: 'brand_pny', label: 'PNY' },
        { value: 'brand_sabrent', label: 'Sabrent' },
        { value: 'brand_samsung', label: 'Samsung' },
        { value: 'brand_sandisk', label: 'SanDisk' },
        { value: 'brand_seagate', label: 'Seagate' },
        { value: 'brand_solidigm', label: 'SOLIDIGM' },
        { value: 'brand_ssk', label: 'SSK' },
        { value: 'brand_storite', label: 'Storite' },
        { value: 'brand_suhsai', label: 'SUHSAI' },
        { value: 'brand_synology', label: 'Synology' },
        { value: 'brand_toshiba', label: 'Toshiba' },
        { value: 'brand_transcend', label: 'Transcend' },
        { value: 'brand_unionsine', label: 'UnionSine' },
        { value: 'brand_vansuny', label: 'Vansuny' },
        { value: 'brand_verbatim', label: 'Verbatim' },
        { value: 'brand_westerndigital', label: 'Western Digital' },
        //{ value: 'brand_alxum', label: 'Alxum' },
        //{ value: 'brand_hurricate', label: 'HURRICANE' },
        //{ value: 'brand_banapoy', label: 'banapoy' },
        //{ value: 'brand_blueendless', label: 'Blueendless' },
        //{ value: 'brand_cecotec', label: 'Cecotec' },
        //{ value: 'brand_hahhy', label: 'HAHHY' },
        //{ value: 'brand_sincelo', label: 'Sincelo' },
        //{ value: 'brand_uiuit', label: 'UIUIT' },
        //{ value: 'brand_wooge', label: 'Wooge' },


    ]
};

const checbkoxOptions = {
    checkboxMisc: [
        {
            "id": "check-is_refurbished",
            "name": "is_refurbished",
            "label": "Refurbished",
            "propertyName": "is_refurbished"
        },
        {
            "id": "check-is_ssd",
            "name": "is_ssd",
            "label": "Nur SSD",
            "propertyName": "is_ssd"
        },
        {
            "id": "check-is_nvme",
            "name": "is_nvme",
            "label": "Nur NVMe",
            "propertyName": "is_nvme"
        },
        {
            "id": "check-is_internal",
            "name": "is_internal",
            "label": "Nur intern (kein USB)",
            "propertyName": "is_internal"
        },
        {
            "id": "check-is_usb",
            "name": "is_usb",
            "label": "Nur USB",
            "propertyName": "is_usb"
        },
        {
            "id": "check-is_enterprise",
            "name": "is_enterprise",
            "label": "Enterprise / 24x7",
            "propertyName": "is_enterprise"
        }
    ]
}

export const formConfig = {

    checkboxFormConfiguration: [
        {
            "label": "Sonstiges",
            "name": "misc",
            "source": checbkoxOptions.checkboxMisc
        }
    ],

    selectFormConfiguration: [
        {
            label: 'Preis',
            placeholder_select: 'Preis',
            name: 'price',
            selectOptions: selectOptions.selectOptionsPrices
        },
        {
            label: 'Speicherplatz',
            placeholder_select: 'Speicherplatz',
            name: 'size_gb',
            selectOptions: selectOptions.selectOptionsSizeGb
        },
        {
            label: 'RPM (Umdrehungen pro Minute)',
            placeholder_select: 'Rpm',
            name: 'rpm',
            selectOptions: selectOptions.selectRpm
        },
        {
            label: 'Max. Geschwindigkeit',
            placeholder_select: 'Max. Geschwindigkeit',
            name: 'max_speed',
            selectOptions: selectOptions.selectMaxSpeed
        },
        {
            label: 'Formfaktor',
            placeholder_select: 'Formfaktor',
            name: 'form_factor',
            selectOptions: selectOptions.selectFormFactor
        },
        {
            label: 'Top Marken',
            placeholder_select: 'Top Marken',
            name: 'brand',
            selectOptions: selectOptions.selectOptionsBrand
        },
        {
            label: 'Sterne bei Amazon',
            placeholder_select: 'Sterne',
            name: 'stars',
            selectOptions: selectOptions.selectOptionsStars
        }
    ]
}